import React, { Component, Fragment } from "react";
import Image from "next/image";
import Link from "next/link";
import { Container, Dropdown, Form, Navbar } from "react-bootstrap";
import styles from "./Header.module.scss";
import { useRouter, withRouter } from "next/router";

import logo_mobile from "../../public/21Spades-logo-m.png";
import logo_mobile_dark from "../../public/21Spades-logo-m.png";
import svg_logo from "../../public/img/svg_logo.svg";
// API helper
import { apiHelperBlockchain } from "../../helper/apiHelper";
// Icons

import {
  CloseIcon,
  DarkModeIcon,
  DownArrowIcon,
  LanguageIcon,
  LightModeIcon,
  MenuIocn,
  MoreIcon,
  SearchIcon,
  NotificationIcon,
  NoNotificationIcon,
} from "../icons";

import logo_light from "../../public/21Spades-logo-m.png";
import logo_dark from "../../public/21Spades-logo-m.png";
import { useTranslation, withTranslation } from "next-i18next";
import { ToastContainer } from "react-toastify";

// common
import SearchOpenHeader from "./searchOpenHeader";
import MoralisLogOut from "./moralisLogOut";
import MoralisWalletBalance from "./moralisWalletBalance";
import LoggedInUser from "./loggedInUser";

import { firebaseCloudMessaging } from "../../helper/firebaseHelper";
import { calcDate } from "../../helper/utilityHelper";

const ExploreListItem = ({ item, currLocale, hideMenu }) => {
  const { t } = useTranslation();
  return (
    <li key={item.name}>
      <Link locale={currLocale} href={`/${item.linkName}`}>
        <a className="dropdown-item" onClick={hideMenu}>
          <Image
            src={item.img}
            alt={t("common:explore")}
            width={16}
            height={16}
            layout="responsive"
          />
          {item.name}
        </a>
      </Link>
    </li>
  );
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // search
      searchOpen: false,
      isSearchOpen: false,
      searchLoader: false,
      searchResult: "",
      isClose: false,
      search: "",

      isDark: false,
      isUserLoggedIn: false,
      walletAddress: "",
      account_balance: 0,
      userData: "",
      userDetails: "",
      categoryList: [],
      windowWidth: 0,

      // Copy Address
      cAddress: false,
      moralisLogout: false,

      // chainType
      chainType: "avax",

      // notifications
      notifications: [],
      unreadNotifications: 0,
      notificationLoader: true,
      ifId: false,
    };
  }

  componentDidMount = async () => {
    if (
      localStorage.getItem("chainType") &&
      localStorage.getItem("chainType") !== ""
    ) {
      let chainType = localStorage.getItem("chainType");
      this.setState({ chainType: chainType });
    }

    if (
      localStorage.getItem("userData") &&
      localStorage.getItem("userData") !== ""
    ) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      let walletAddress = userData.walletAddress;
      let accountBalance = 0;
      if (localStorage.getItem("accountBalance")) {
        accountBalance = localStorage.getItem("accountBalance");
      }
      this.setState({
        userData: userData,
        isUserLoggedIn: true,
        walletAddress: walletAddress,
        account_balance: Number.parseFloat(accountBalance).toFixed(6),
      });
    }

    if (window.location.href.includes("/id")) {
      this.setState({ ifId: true });
    }

    await this.getUserDetails();
    await this.getAllCategory();
    this.getUserNotifications();

    if (typeof window !== "undefined") {
      this.setState({
        windowWidth: window.innerWidth,
      });
    }
    if (window.location.pathname !== '/feed') {
      window.onscroll = function () {
        if (window?.pageYOffset > 0) {
          document.getElementById("header")?.classList.add(styles.navbarScroll);
        } else {
          document.getElementById("header")?.classList.remove(styles.navbarScroll);
        }
      };
    }
    if (window.location.pathname !== '/feed') {
      window.addEventListener("click", (event) => {
        var ignoreClickOnMeElement = document.getElementById("someElementID");

        var isClickInsideElement = ignoreClickOnMeElement?.contains(event.target);
        if (!isClickInsideElement) {
          this.setState({ isSearchOpen: false });
        } else {
          this.setState({ isSearchOpen: true });
        }
      });
    }
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        const data = event.data.firebaseMessaging.payload;
        if (data.data) {
          const payloadData = data.data;
          const constructNotfData = {
            _id: payloadData._id,
            isSingle: true,
            title: data.notification.title,
            description: data.notification.body,
            isRead: false,
            payload: {
              user: payloadData.user,
              imageURL: payloadData.imageURL,
              nft_url: payloadData.nft_url,
            },
            createdAt: new Date(payloadData.createdAt).toISOString(),
            updatedAt: new Date(payloadData.createdAt).toISOString(),
          };

          this.setState({
            notifications: [constructNotfData, ...this.state.notifications],
            unreadNotifications: this.state.unreadNotifications + 1,
          });
        }
      });
    }
  };

  changeSearch = async (e) => {
    let ser = e.target.value;
    this.setState({ search: ser });
    if (ser && ser !== "") {
      this.setState({ isClose: true });
    }
    if (ser && ser !== "" && ser.length > 2) {
      this.setState({ isSearchOpen: true, searchLoader: true });

      let nData = { searchField: ser.trim() };

      await apiHelperBlockchain("home-search", "POST", nData)
        .then((resp) => {
          if (resp.data.status === true) {
            this.setState({
              searchLoader: false,
              searchResult: resp.data.data,
            });
          }
        })
        .catch((error) => console.error(`Error: ${error}`));
    } else {
      this.setState({ isSearchOpen: false, isClose: false });
    }
  };

  getAllCategory = async () => {
    try {
      const response = await apiHelperBlockchain("category-list", "GET");
      if (response.data.status) {
        this.setState({ categoryList: response.data.data });
      } else {
        console.error(`Error: Category list API returned unsuccessful status`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  componentDidUpdate(_prevProps, prevState) {
    if (window.pageYOffset > 0) {
      document.getElementById("header")?.classList.add(styles.navbarScroll);
    } else {
      document.getElementById("header")?.classList.remove(styles.navbarScroll);
    }
    if (prevState.isUserLoggedIn !== this.state.isUserLoggedIn) {
      if (
        localStorage.getItem("walletAddress") &&
        localStorage.getItem("walletAddress") !== ""
      ) {
        this.setState({ isUserLoggedIn: true });
      } else {
        this.setState({ isUserLoggedIn: false });
      }
    }
  }

  // get UserDetails
  getUserDetails = async () => {
    let walletAddress = localStorage.getItem("walletAddress");
    let userdata = localStorage.getItem("userData");
    let wData = { wallet_address: walletAddress };

    if (userdata) {
      await apiHelperBlockchain("get-user", "POST", wData)
        .then((res) => {
          if (res.data.status) {
            if (res.data.data.length > 0) {
              this.setState({ userDetails: res.data.data[0] });
            }
            if (res.data.data.length > 0) {
              localStorage.setItem("displayName", res?.data?.data[0]?.displayName);
              localStorage.setItem("customURL", res?.data?.data[0]?.custom_url);
              localStorage.setItem("user_id", res?.data?.data[0]?._id);
            } else {
              localStorage.setItem("displayName", "");
              localStorage.setItem("customURL", "");
              localStorage.setItem("user_id", "");
            }
          }
        })
        .catch((error) => console.error(`Error: ${error}`));
    } else {
      this.setState({ isUserLoggedIn: false, walletAddress: "" });
    }
  };

  //get user notifications
  getUserNotifications = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      let authentication = null;
      if (accessToken) {
        authentication = { authorization: `Bearer ${accessToken}` };
      }

      const res = await apiHelperBlockchain(
        "notifications",
        "GET",
        {},
        authentication
      );

      if (res?.data?.status) {
        const notifications = [...this.state.notifications, ...res.data.data];
        const unreadMessageCount = notifications.filter(
          (notification) => !notification.isRead
        ).length;

        this.setState({
          notifications,
          notificationLoader: false,
          unreadNotifications: unreadMessageCount,
        });
      }
    } catch (error) {
      this.setState({ notificationLoader: false });
    }
  };

  openSearch = () => {
    this.setState({ searchOpen: true, isSearchOpen: true });
  };

  toggle = (e) => {
    e.preventDefault();
    if (typeof document !== "undefined") {
      if (document.getElementById("toggler")) {
        document.getElementById("toggler").classList.toggle(styles.show);
        if (
          document.getElementById("toggler").classList.contains(styles.show)
        ) {
          document.getElementById("navbarCollapse").style.display = "block";
        } else {
          document.getElementById("navbarCollapse").style.display = "none";
        }
      }
    }
  };
  hideMenu = () => {
    if (this.state.windowWidth <= 991) {
      if (typeof document !== "undefined") {
        if (
          document.getElementById("toggler") &&
          document.getElementById("toggler").classList.contains(styles.show)
        ) {
          document.getElementById("navbarCollapse").style.display = "none";
          document.getElementById("toggler").classList.remove(styles.show);
        }
      }
    }
  };
  hideMenu1 = (e) => {
    e.preventDefault();
    if (this.state.windowWidth <= 991) {
      if (typeof document !== "undefined") {
        if (
          document.getElementById("toggler") &&
          document.getElementById("toggler").classList.contains(styles.show)
        ) {
          document.getElementById("navbarCollapse").style.display = "none";
          document.getElementById("toggler").classList.remove(styles.show);
        }
      }
    }
  };

  createNFT = (e) => {
    this.props.router.push(`/${this.props.currLocale}/nft/upload-variants`);
    this.hideMenu1(e);
  };

  signIn = (e) => {
    this.props.router.push(`/${this.props.currLocale}/nft/login`);
    this.hideMenu1(e);
  };

  goToHome = () => {
    window.location = "/" + this.props.currLocale + "/nft";
  };

  signout = async () => {
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("accountBalance");
    localStorage.removeItem("connectorID");
    localStorage.removeItem("userData");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user_id");
    localStorage.clear();
    await firebaseCloudMessaging.delToken();
    this.setState({ moralisLogout: true });
    window.location = "/" + this.props.currLocale + "/nft";
  };

  copyAddress = (pAddress) => {
    if (
      typeof window !== "undefined" &&
      typeof window.navigator !== "undefined"
    ) {
      navigator.clipboard.writeText(pAddress);
    }
    this.setState({ cAddress: true });
    setTimeout(() => {
      this.setState({ cAddress: false });
    }, 2500);
  };

  sendLink = (link) => {
    window.location = "/" + this.props.currLocale + link;
  };

  showNotification = (notification) => {
    return (
      <>
        <li className={`${styles.notfList}`}>
          <a className="d-flex" href={notification?.payload?.nft_url}>
            <div className={styles.details}>
              <div className={styles.name}>{notification.title}</div>
              <div className={styles.text1}>{notification.description}</div>
              <div className={styles.text2}>
                {calcDate(new Date(), new Date(notification.createdAt))}
              </div>
            </div>
          </a>
        </li>
      </>
    );
  };

  markNotfRead = async () => {
    try {
      if (this.state.unreadNotifications > 0) {
        const accessToken = localStorage.getItem("accessToken");
        let authentication = null;
        if (accessToken) {
          authentication = { authorization: `Bearer ${accessToken}` };
        }
        await apiHelperBlockchain(
          "notifications/mark-read/all",
          "POST",
          {},
          authentication
        );

        this.setState({ unreadNotifications: 0 });
      }
    } catch (error) { }
  };

  renderExploreList = (exploreData) => {
    return (
      exploreData &&
      exploreData?.map((item) => (
        <ExploreListItem
          key={item.name}
          item={item}
          currLocale={this.props.currLocale}
          hideMenu={this.hideMenu}
        />
      ))
    );
  };

  returnHeaderForIdPages = (validdashBoardPathnames, linkHref, t) => {
    const { router } = this.props;
    if (router?.pathname && validdashBoardPathnames.includes(router.pathname)) {
      return (
        <>
          <div id="header"></div>
        </>
      );
    } else {
      return (
        <>
          <header
            className={`${styles.header} ${styles.didHeader}`}
            id="header"
          >
            <Container className="d-flex align-items-center">
              <Link locale={this.props.currLocale} href={linkHref}>
                <a
                  className={`logo_style d-flex ${styles.header__logo}`}
                  onClick={this.hideMenu}
                >
                  <Image
                    src={svg_logo}
                    alt="21 Spades"
                    width={174}
                    height={34}
                    layout="fixed"
                  />
                  <span className={styles.beta}>Beta</span>
                </a>
              </Link>

              <div className={styles.header__wrapper}>
                <Navbar expand="lg" className={`ms-auto p-0 ${styles.navbar}`}>
                  <div
                    className={`btn buttonStroke buttonSmall d-flex d-lg-none ${styles.navbar_toggler}`}
                    id="toggler"
                    onClick={this.toggle}
                  >
                    <MenuIocn
                      id="collapsedToggle"
                      className={styles.collapsedToggle}
                    />
                    <CloseIcon
                      id="navbar_expand"
                      className={styles.navbar_expand}
                    />
                  </div>

                  <div
                    className={`navbar-collapse ${styles.navbarCollapse}`}
                    id="navbarCollapse"
                  >
                    <ul className="me-auto d-lg-flex mb-0 ps-0 align-items-center">
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/certs`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                Certs
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/web`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                Web3 ID
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/wallet`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                Wallet
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li>
                        <Dropdown
                          id="category"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-explore"
                          >
                            Industries
                            <DownArrowIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                          <div
                            className={`dropdown-menu ${styles.dropdownMenu}`}
                          >
                            <ul className={styles.menu}>
                              <li>
                                <Link href={`${linkHref}/education`}>
                                  <a
                                    className="dropdown-item"
                                    onClick={this.hideMenu}
                                  >
                                    Education
                                  </a>
                                </Link>
                              </li>
                              <li>
                                <Link href={`${linkHref}/metaverse`}>
                                  <a
                                    className="dropdown-item"
                                    onClick={this.hideMenu}
                                  >
                                    Metaverse
                                  </a>
                                </Link>
                              </li>
                              <li>
                                <Link href={`${linkHref}/healthcare`}>
                                  <a
                                    className="dropdown-item"
                                    onClick={this.hideMenu}
                                  >
                                    Healthcare
                                  </a>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  href={`${linkHref}/identity-access-management`}
                                >
                                  <a
                                    className="dropdown-item"
                                    onClick={this.hideMenu}
                                  >
                                    Identity & Access Management
                                  </a>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Dropdown>
                      </li>
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/#howWorks`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                How it works
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/#useCase`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                Use Cases
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                      <li className={styles.eventLinks}>
                        <Dropdown
                          id="events"
                          className={`nav-item ${styles.headerItem}`}
                        >
                          <Dropdown.Toggle
                            variant="link"
                            className={`nav-link ${styles.navLink}`}
                            id="dropdown-community"
                          >
                            <Link href={`${linkHref}/#AboutUs`}>
                              <a
                                className={styles.eventsName}
                                onClick={this.hideMenu}
                              >
                                About Us
                              </a>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={`d-none ${styles.createMenuList}`}
                          ></Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                    {/* mobile btn */}
                    <div
                      className={`d-flex d-md-none me-0 ${styles.actionBtn}`}
                    >
                      <Link href={`${linkHref}/login`}>
                        <a
                          className={`btn buttonSmall buttonStroke ${styles.btnClassName} ${styles.header__upload}`}
                          onClick={this.hideMenu}
                        >
                          {t("common:log_in")}
                        </a>
                      </Link>
                      <Link href={`${linkHref}/login`}>
                        <a
                          className={`btn buttonSmall ${styles.btnClassName} ${styles.header__upload}`}
                          onClick={this.hideMenu}
                        >
                          {t("common:Try_Free")}
                        </a>
                      </Link>
                    </div>
                  </div>
                </Navbar>
                <div className={`d-none d-md-flex p-0 ${styles.actionBtn}`}>
                  <Link href={`${linkHref}/login`}>
                    <a
                      className={`btn buttonSmall buttonStroke ${styles.btnClassName} ${styles.header__upload}`}
                      onClick={this.hideMenu}
                    >
                      {t("common:log_in")}
                    </a>
                  </Link>
                  <Link href={`${linkHref}/login`}>
                    <a
                      className={`btn buttonSmall ${styles.btnClassName} ${styles.header__upload}`}
                      onClick={this.hideMenu}
                    >
                      {t("common:Try_Free")}
                    </a>
                  </Link>
                </div>
              </div>
            </Container>
          </header>
        </>
      );
    }
  };
  render() {
    const { currLocale, t, router } = this.props;

    let exploreList = [
      {
        id: 1,
        name: "Avalanche",
        linkName: "nft/explore/avalanche",
        img: "/img/Avalanche_logo.png",
      },
      // { id: 2, name: t('common:ethereum'), linkName: 'nft/explore/ethereum', img: avalancheImg },
      // { id: 3, name: t('common:polygon'), linkName: 'nft/explore/polygon', img: nexaImg },
    ];

    let exploreUpcoming = [
      {
        id: 3,
        name: "Nexa",
        linkName: "nft/explore/nexa",
        img: "/img/nexa-logo-mark.png",
      },
      // { id: 4, name: t('common:flow'), linkName: 'nft/explore/flow', img: flowImg },
    ];
    let categeryUpcoming = [
      {
        id: 4,
        name: "Ticketing",
        linkName: "nft/explore/ticketing",
        img: "/img/ticketing.webp",
      },
      {
        id: 5,
        name: "Real Estate",
        linkName: "nft/explore/realestate",
        img: "/img/real-estate.webp",
      },
      // { id: 4, name: t('common:flow'), linkName: 'nft/explore/flow', img: flowImg },
    ];

    let userUrl = this.state.walletAddress;
    if (this.state.userDetails && this.state.userDetails.customUrl) {
      userUrl = this.state.userDetails.customUrl;
    }

    let equivalentSlug = "";
    const isEquivalentSlugPage =
      (router.asPath && router.asPath.includes("/events")) ||
      (router.pathname &&
        router.pathname === "/events/category/[categoryName]");
    if (this.props.equivalentSlug && this.props.equivalentSlug !== "") {
      equivalentSlug = "/" + this.props.equivalentSlug;
    }

    const dynamicUrlNft = this.props.dynamicUrl
      ? this.props.dynamicUrl
      : "/nft";
    let urlEN = `${process.env.hostBaseUrl}/en${equivalentSlug !== "" ? equivalentSlug : dynamicUrlNft
      }`;
    let urlAR = `${process.env.hostBaseUrl}/ar${equivalentSlug !== "" ? equivalentSlug : dynamicUrlNft
      }`;
    const enActiveStyle = currLocale === "en" ? styles.active : "";
    const arActiveStyle = currLocale === "ar" ? styles.active : "";
    const equivalentSlugStyle =
      isEquivalentSlugPage && equivalentSlug === "" ? "disabled" : "";

    const linkHref = this.state.ifId ? "/id" : "/did";

    const didRoutes = [
      "/[did]",
      "/[did]/certs",
      "/[did]/web",
      "/[did]/wallet",
      "/[did]/about-us",
      "/[did]/use-cases",
      "/[did]/how-it-works",
      "/[did]/identity-access-management",
      "/[did]/healthcare",
      "/[did]/metaverse",
      "/[did]/education",
      "/[did]/use-case",
    ];
    const didDashboardRoutes = [
      "/[did]/dashboard",
      "/[did]/credentials",
      "/[did]/issue",
      "/[did]/template",
      "/[did]/verifiable",
      "/[did]/verification",
      "/[did]/create-template",
      "/[did]/verification-template",
      "/[did]/verification-history",
      "/[did]/verify-credentials",
      "/[did]/login",
      "/[did]/signup",
    ];

    return (
      <>
        {!(
          router?.pathname &&
          (didRoutes.includes(router.pathname) ||
            didDashboardRoutes.includes(router.pathname))
        ) ? (
          <>
            {this.state.moralisLogout ? <MoralisLogOut /> : <Fragment />}
            <MoralisWalletBalance
              getTokenBalance={(bal) =>
                bal &&
                this.setState({
                  account_balance: Number.parseFloat(bal).toFixed(6),
                })
              }
            />
            <header
              className={
                styles.header +
                " " +
                (this.state.searchOpen ? styles.search_show : "")
              }
              id="header"
            >
              <Container>
                {this.state.searchOpen ? (
                  <SearchOpenHeader
                    sendLink={this.sendLink}
                    changeSearch={this.changeSearch}
                    handleClose={() =>
                      this.setState({
                        isClose: false,
                        isSearchOpen: false,
                        search: "",
                        searchResult: "",
                      })
                    }
                    handleCloseIcon={() => this.setState({ searchOpen: false })}
                    {...this.state}
                  />
                ) : (
                  // <></>
                  <div className="d-flex align-items-center">
                    <Link locale={this.props.currLocale} href="/nft">
                      <a
                        className={`logo_style d-flex align-items-center ${styles.header__logo}`}
                        onClick={() => this.goToHome()}
                      >
                        <span className="light">
                          <span className="d-none d-sm-block">
                            <Image
                              src={"/public/upyo-default.png"}
                              alt="21 Spades"
                              width={102}
                              height={33}
                              layout="fixed"
                            />
                          </span>
                          <span className="d-block d-sm-none">
                            <Image
                              src={"/public/upyo-default.png"}
                              alt="21 Spades"
                              width={40}
                              height={40}
                              layout="fixed"
                            />
                          </span>
                        </span>
                        <span className="dark">
                          <span className="d-none d-sm-block">
                            <Image
                              src={logo_dark}
                              alt="21 Spades"
                              width={102}
                              height={45}
                              layout="fixed"
                            />
                          </span>
                          <span className="d-block d-sm-none">
                            <Image
                              src={logo_mobile_dark}
                              alt="21 Spades"
                              width={45}
                              height={40}
                              layout="fixed"
                            />
                          </span>
                        </span>
                        <div className={styles.logo}>21Spades</div>
                      </a>
                    </Link>
                    <div className={styles.header__wrapper}>
                      <SearchOpenHeader
                        sendLink={this.sendLink}
                        changeSearch={this.changeSearch}
                        handleClose={() =>
                          this.setState({
                            isClose: false,
                            isSearchOpen: false,
                            search: "",
                            searchResult: "",
                          })
                        }
                        handleCloseIcon={() =>
                          this.setState({ searchOpen: false })
                        }
                        {...this.state}
                      />
                      <Navbar
                        expand="lg"
                        className={`me-auto m-lg-auto p-0 ${styles.navbar}`}
                      >
                        <div
                          className={`btn buttonStroke buttonSmall d-flex d-lg-none ${styles.navbar_toggler}`}
                          id="toggler"
                          onClick={this.toggle}
                        >
                          <MenuIocn
                            id="collapsedToggle"
                            className={styles.collapsedToggle}
                          />
                          <CloseIcon
                            id="navbar_expand"
                            className={styles.navbar_expand}
                          />
                        </div>

                        <div
                          className={`navbar-collapse ${styles.navbarCollapse}`}
                          id="navbarCollapse"
                        >
                          <ul className="me-auto d-lg-flex mb-0 ps-0 align-items-center">
                            <li>
                              <Dropdown
                                id="explore"
                                className={`nav-item ${styles.headerItem}`}
                              >
                                <Dropdown.Toggle
                                  variant="link"
                                  className={`nav-link ${styles.navLink}`}
                                  id="explore-community"
                                >
                                  {t("common:explore")}
                                  <DownArrowIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`d-none ${styles.createMenuList}`}
                                />
                                <ul
                                  className={`dropdown-menu ${styles.dropdownMenu}`}
                                >
                                  <li>
                                    <ul className="p-0">
                                      {this.renderExploreList(exploreList)}
                                    </ul>
                                    <ul
                                      className={
                                        styles.upcoming + " disableDiv"
                                      }
                                    >
                                      {this.renderExploreList(exploreUpcoming)}
                                    </ul>
                                  </li>
                                </ul>
                              </Dropdown>
                            </li>
                            <li>
                              <Dropdown
                                id="explore"
                                className={`nav-item ${styles.headerItem}`}
                              >
                                <Dropdown.Toggle
                                  variant="link"
                                  className={`nav-link ${styles.navLink}`}
                                  id="explore-community"
                                >
                                  {t("common:category")}
                                  <DownArrowIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`d-none ${styles.createMenuList}`}
                                />
                                <ul
                                  className={`dropdown-menu ${styles.dropdownMenu}`}
                                >
                                  <li>
                                    <ul className={styles.menu}>
                                      <li>
                                        <Link
                                          locale={this.props.currLocale}
                                          href="/nft/category"
                                        >
                                          <a
                                            className="dropdown-item"
                                            onClick={this.hideMenu}
                                          >
                                            ❇️ {t("common:all_items")}
                                          </a>
                                        </Link>
                                      </li>
                                      {this.state.categoryList &&
                                        this.state.categoryList.length > 0 &&
                                        this.state.categoryList.map(
                                          (item, idx) => (
                                            <li key={idx}>
                                              <Link
                                                locale={this.props.currLocale}
                                                href={`/nft/category/${encodeURI(
                                                  item.page_name
                                                )}`}
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  onClick={this.hideMenu}
                                                >
                                                  {item?.category_img ?
                                                    <Image
                                                      src={item?.category_img || ""}
                                                      alt={item?.category_name}
                                                      width={20}
                                                      height={20}
                                                      layout="responsive"
                                                    />
                                                    : null}
                                                  {currLocale === "en"
                                                    ? item.category_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    item.category_name.slice(
                                                      1
                                                    )
                                                    : item.category_name_arabic}
                                                </a>
                                              </Link>
                                            </li>
                                          )
                                        )}
                                    </ul>

                                    <ul
                                      className={
                                        styles.upcoming + " disableDiv"
                                      }
                                    >
                                      {this.renderExploreList(categeryUpcoming)}
                                    </ul>
                                  </li>
                                </ul>
                              </Dropdown>
                            </li>

                            <li>
                              <Dropdown
                                id="trending"
                                className={`nav-item ${styles.headerItem}`}
                              >
                                <Dropdown.Toggle
                                  variant="link"
                                  className={`nav-link ${styles.navLink}`}
                                  id="trending-community"
                                >
                                  {t("common:trending")}
                                  <DownArrowIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`d-none ${styles.createMenuList}`}
                                ></Dropdown.Menu>
                                <ul
                                  className={`dropdown-menu ${styles.dropdownMenu}`}
                                >
                                  <li>
                                    <Link
                                      locale={this.props.currLocale}
                                      href="/nft/collections"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={this.hideMenu}
                                      >
                                        {t("common:popular_NFT")}
                                      </a>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      locale={this.props.currLocale}
                                      href="/nft/tokens"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={this.hideMenu}
                                      >
                                        {t("common:top_tokens")}
                                      </a>
                                    </Link>
                                  </li>
                                  {/* <li><Link locale={this.props.currLocale} href="/nft/profiles"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:top_profiles')}</a></Link></li>
                                                                    <li><Link locale={this.props.currLocale} href="/nft/calendar"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:NFT_calendar')}</a></Link></li> */}
                                  <li>
                                    <Link
                                      locale={this.props.currLocale}
                                      href="/nft/live-auction"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={this.hideMenu}
                                      >
                                        {t("common:live_auctions")}
                                      </a>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      locale={this.props.currLocale}
                                      href="/feed"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={this.hideMenu}
                                      >
                                        Feeds
                                      </a>
                                    </Link>
                                  </li>
                                </ul>
                              </Dropdown>
                            </li>
                            <li
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                            >
                              <Link
                                id="topCategory"
                                className={`nav-item ${styles.headerItem}`}
                                href="/en/chat"
                              >
                                <div>
                                  <span>Community</span>
                                  <span>
                                    <img
                                      src="/chat-icon.svg"
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </span>
                                </div>
                              </Link>
                            </li>
                            {/* <li>
                                                            <Dropdown id="tools" className={`nav-item ${styles.headerItem}`}>
                                                                <Dropdown.Toggle variant="link" className={`nav-link ${styles.navLink}`} id="tools-community">
                                                                    {t('common:tools')}
                                                                    <DownArrowIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className={`d-none ${styles.createMenuList}`}></Dropdown.Menu>
                                                                <ul className={`dropdown-menu ${styles.dropdownMenu}`}>
                                                                    <li><Link locale={this.props.currLocale} href="/nft/tools/ens-domains"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:ENS_domain')}</a></Link></li>
                                                                </ul>
                                                            </Dropdown>
                                                        </li> */}
                            {/* <li className={styles.eventLinks}>
                                                            <Dropdown id="events" className={`nav-item ${styles.headerItem}`}>
                                                                <Dropdown.Toggle variant="link" className={`nav-link ${styles.navLink}`} id="events-community">
                                                                    <Link locale={this.props.currLocale} href="/events">
                                                                        <a className={styles.eventsName} onClick={this.hideMenu}>
                                                                            {t('common:events_nav')}
                                                                            <span className={styles.hotLbl}>{t('common:new_lbl')}</span>
                                                                        </a>
                                                                    </Link>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className={`d-none ${styles.createMenuList}`}></Dropdown.Menu>
                                                            </Dropdown>
                                                        </li> */}
                            {/* <li>
                                                            <Dropdown id="more" className={`nav-item ${styles.headerItem}`}>
                                                                <Dropdown.Toggle variant="link" className={`nav-link ${styles.navLink}`} id="more-community">
                                                                    <MoreIcon className={styles.NavMoreIcon} />
                                                                    <DownArrowIcon className="d-block d-lg-none" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className={`d-none ${styles.createMenuList}`}></Dropdown.Menu>
                                                                <div className={`dropdown-menu ${styles.dropdownMenu}`}>
                                                                    <ul className={styles.menu}>
                                                                        <li><Link locale={this.props.currLocale} href="/nft/about"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:about')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="https://airtable.com/shrQTtJAcEJMxLbMW"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:partner')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="https://airtable.com/shrIb0wREBDSHOHzA"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:Celebrities')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="/nft/app"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:nft_app')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="https://airtable.com/shrBlTqqGPm7upGVg"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:Projects')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="/blog"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:blog')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="/news"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:news')}</a></Link></li>
                                                                        <li><Link href={`https://help.21Spades.com/${this.props.currLocale}`}><a className='dropdown-item' onClick={this.hideMenu}>{t('common:help_link')}</a></Link></li>
                                                                        <li><Link locale={this.props.currLocale} href="/nft/contact"><a className='dropdown-item' onClick={this.hideMenu}>{t('common:help_center')}</a></Link></li>
                                                                    </ul>
                                                                </div>
                                                            </Dropdown>
                                                        </li> */}
                          </ul>
                          {/* mobile btn */}
                          <div
                            className={`d-flex d-md-none me-0 ${styles.actionBtn}`}
                          >
                            <button
                              id="navDiv"
                              className={`btn buttonSmall ${styles.btnClassName}`}
                              onClick={this.createNFT}
                            >
                              {t("common:create")}
                            </button>
                            {!this.state.isUserLoggedIn && (
                              <button
                                id="navDiv"
                                className={`btn buttonStroke buttonSmall ${styles.header__upload}`}
                                onClick={this.signIn}
                              >
                                Connect wallet
                              </button>
                            )}
                          </div>
                        </div>
                      </Navbar>
                      {/* desktop btn */}
                      <div className={`d-flex p-0 ${styles.actionBtn}`}>
                        <div
                          className={`d-none d-xl-block ${styles.langWithCurr}`}
                        >
                          <Dropdown className={`nav-item ${styles.headerItem}`}>
                            <Dropdown.Toggle
                              variant="link"
                              className={`nav-link ${styles.navLink}`}
                              id="language-desktop"
                            >
                              {currLocale && currLocale.toUpperCase()}
                              <DownArrowIcon />
                            </Dropdown.Toggle>
                            <div
                              className={`dropdown-menu ${styles.dropdownMenu}`}
                            >
                              <div className={styles.header__col}>
                                <div className={styles.header__category}>
                                  {t("common:language")}
                                </div>
                                <div className={styles.header__menu}>
                                  <Link href={urlEN} locale="en">
                                    <a
                                      className={`${styles.header__language} ${enActiveStyle} ${equivalentSlugStyle}`}
                                    >
                                      English
                                    </a>
                                  </Link>

                                  {/* <Link href={urlAR} locale="ar">
                                                                        <a className={`${styles.header__language} ${arActiveStyle} ${equivalentSlugStyle}`}>Arabic</a>
                                                                    </Link> */}
                                </div>
                              </div>
                              <div className={styles.header__col}>
                                {/* <div className={styles.header__category}>{t('common:currency')}</div> */}
                                <div className={styles.header__category}>
                                  Upcoming Language{" "}
                                </div>
                                <div className={styles.header__menu}>
                                  <span
                                    className={`${styles.header__currency + " disableDiv"
                                      }`}
                                  >
                                    Spanish
                                  </span>
                                  <span
                                    className={
                                      styles.header__currency + " disableDiv"
                                    }
                                  >
                                    Russian
                                  </span>
                                  <span
                                    className={
                                      styles.header__currency + " disableDiv"
                                    }
                                  >
                                    Portuguese
                                  </span>
                                  <span
                                    className={
                                      styles.header__currency + " disableDiv"
                                    }
                                  >
                                    German
                                  </span>
                                  <span
                                    className={
                                      styles.header__currency + " disableDiv"
                                    }
                                  >
                                    French
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                        {this.state.isUserLoggedIn ? (
                          <div className={`${styles.header__notification}`}>
                            <Dropdown
                              className={styles.Dropdown}
                              onClick={this.markNotfRead}
                            >
                              <Dropdown.Toggle
                                aria-label="more"
                                className={`btn buttonStroke buttonSmall ${styles.notbtn}`}
                                id="dropdown-not"
                              >
                                <NotificationIcon />
                                {!this.state.notificationLoader &&
                                  this.state.unreadNotifications > 0 && (
                                    <span
                                      className={`d-flex justify-content-center align-items-center ${styles.totalItems}`}
                                    >
                                      {this.state.unreadNotifications}
                                    </span>
                                  )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className={`${styles.dropdownMenu}`}
                              >
                                <div
                                  className={`d-flex align-items-center justify-content-between w-100 ${styles.title}`}
                                >
                                  <h2 className={`mb-0 ${styles.userName}`}>
                                    {t("common:Notification")}
                                  </h2>
                                </div>
                                {!this.state.notificationLoader &&
                                  this.state.notifications.length > 0 ? (
                                  <>
                                    <ul className={styles.lists}>
                                      {this.state.notifications.map(
                                        (notification) =>
                                          this.showNotification(notification)
                                      )}
                                    </ul>
                                    <div className={styles.see_activity}>
                                      <Link
                                        href={`/nft/profiles/${this.state.walletAddress}?tab=activity`}
                                        passHref
                                      >
                                        <button className={`btn buttonSmall`}>
                                          {t("common:see_activity")}
                                        </button>
                                      </Link>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`text-center ${styles.noNotification}`}
                                    >
                                      <NoNotificationIcon />
                                      <div className={`h4 ${styles.title}`}>
                                        {t("no_notification")}
                                      </div>
                                      <p>{t("common:noti_desc")}</p>
                                    </div>
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                        <div
                          className={`position-relative d-flex align-items-center ${styles.createMenu}`}
                        >
                          <button
                            id="createId"
                            className={`btn buttonSmall d-none d-md-block ${styles.btnClassName} ${styles.header__upload}`}
                            onClick={this.createNFT}
                          >
                            {t("common:create")}
                          </button>
                          <LoggedInUser
                            {...this.state}
                            copyAddress={this.copyAddress}
                            currLocale={this.props.currLocale}
                            userUrl={userUrl}
                            signout={this.signout}
                            signIn={this.signIn}
                          />
                        </div>
                        {/* <div className={`btn buttonStroke p-0 buttonSmall ${styles.header__upload} ${styles.changeModeIO}`} onClick={this.props.toggleTheme}>
                                                    <div className="light">
                                                        <LightModeIcon />
                                                    </div>
                                                    <div className="dark">
                                                        <DarkModeIcon />
                                                    </div>
                                                </div> */}
                        <div
                          className={`btn buttonStroke buttonSmall d-xl-none d-inline-flex p-0 ${styles.btn}`}
                        >
                          <div className={styles.arb}>
                            <Dropdown
                              className={`nav-item ${styles.headerItem}`}
                            >
                              <Dropdown.Toggle
                                variant="link"
                                aria-label="language"
                                className={`nav-link p-0 ${styles.navLink}`}
                                id="language-mobile"
                              >
                                <LanguageIcon className={styles.globalicon} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className={`dropdown-menu ${styles.dropdownMenu}`}
                              >
                                <div className="d-flex">
                                  <div className={styles.header__col}>
                                    <div className={styles.header__category}>
                                      {t("common:language")}
                                    </div>
                                    <div className={styles.header__menu}>
                                      <Link href={urlEN} locale="en">
                                        <a
                                          className={`${styles.header__language} ${enActiveStyle}`}
                                        >
                                          English
                                        </a>
                                      </Link>
                                      {/* <Link href={urlAR} locale="ar">
                                                                                <a className={`${styles.header__language} ${arActiveStyle}`}>Arabic</a>
                                                                            </Link> */}
                                    </div>
                                  </div>
                                  <div className={styles.header__col}>
                                    <div className={styles.header__category}>
                                      Upcoming Language{" "}
                                    </div>
                                    <div className={styles.header__menu}>
                                      <span
                                        className={`${styles.header__currency +
                                          " disableDiv"
                                          }`}
                                      >
                                        Spanish
                                      </span>
                                      <span
                                        className={
                                          styles.header__currency +
                                          " disableDiv"
                                        }
                                      >
                                        Russian
                                      </span>
                                      <span
                                        className={
                                          styles.header__currency +
                                          " disableDiv"
                                        }
                                      >
                                        Portuguese
                                      </span>
                                      <span
                                        className={
                                          styles.header__currency +
                                          " disableDiv"
                                        }
                                      >
                                        German
                                      </span>
                                      <span
                                        className={
                                          styles.header__currency +
                                          " disableDiv"
                                        }
                                      >
                                        French
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div
                          className={`btn buttonStroke buttonSmall p-0 d-block d-xl-none ${styles.header__search}`}
                        >
                          <Form.Control
                            className={styles.header__input}
                            type="text"
                            placeholder={t("common:search")}
                          />
                          <div
                            className={styles.icon}
                            onClick={() => this.openSearch()}
                          >
                            <div className={styles.search}>
                              <SearchIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Container>
              <ToastContainer />
            </header>
          </>
        ) : (
          this.returnHeaderForIdPages(didDashboardRoutes, linkHref, t)
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(Header));
